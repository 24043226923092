import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ContactFormService {

  constructor(public http: HttpClient) { }
   public sendForm(datosForm:any): Observable<any[]> {
    let datos = {
      subject: "Contact From " + datosForm.first_name +" "+ datosForm.surname + "At "+ datosForm.company_name,
      sender: datosForm.email,
      message: datosForm.message,
      cmd: "erpnext.templates.utils.send_message"
    }
     return this.http
       .post<any[]>(`https://erp.collective-energy.africa/contact`, datos);
   }
  
}
